import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// imports
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { LightboxModule } from 'ngx-lightbox';
import { NgxMaskModule } from 'ngx-mask';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgxMasonryModule } from 'ngx-masonry';
import { LazyLoadImageModule } from 'ng-lazyload-image';

// pages
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HeaderInternComponent } from './components/header-intern/header-intern.component';
import { AboutComponent } from './pages/about/about.component';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';
import { PortfolioDetailComponent } from './pages/portfolio-detail/portfolio-detail.component';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogDetailComponent } from './pages/blog-detail/blog-detail.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ContactComponent,
    HeaderInternComponent,
    AboutComponent,
    PortfolioComponent,
    PortfolioDetailComponent,
    BlogComponent,
    BlogDetailComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    LightboxModule,
    HttpClientModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    SweetAlert2Module.forRoot(),
    NgxMasonryModule,
    LazyLoadImageModule,
    SlickCarouselModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
