import { Component, OnInit } from '@angular/core';
import { ContatoGeral } from './models/contato-geral.model';
import { DataService } from './services/data.service';
import { CadastroGeral } from './models/cadastro-geral.model';
import { HelperService } from './services/helper.service';
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";

declare const gtag: Function; // <------------Important: the declartion for gtag is required!

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  contatoGeral: ContatoGeral = new ContatoGeral();
  cadastroGeral: CadastroGeral = new CadastroGeral();

  constructor(
    public _data: DataService,
    public _helper: HelperService,
    private router: Router
  ) {
    /** START : Code to Track Page View using gtag.js */
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        gtag("event", "page_view", {
          page_path: event.urlAfterRedirects,
        });
      });
    /** END : Code to Track Page View  using gtag.js */
  }

  ngOnInit(): void {
    this.buscarCadastroGeral();
    this.buscarContatoGeral();
    this._helper.updateTags();
  }

  buscarCadastroGeral() {
    this._data.buscarCadastroGeral()
      .subscribe((res: CadastroGeral) => this.cadastroGeral = res);
  }

  buscarContatoGeral() {
    this._data.buscarContatoGeral()
      .subscribe((res: ContatoGeral) => this.contatoGeral = res);
  }
}
