import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Sobre } from 'src/app/models/sobre.model';
import { Subscription } from 'rxjs';
import { Cabecalho } from 'src/app/models/cabecalho.model';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  sobre: Sobre = new Sobre();
  buscarSubscription: Subscription;

  html: SafeHtml;

  constructor(
    public _data: DataService,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.buscarSobre();
  }

  ngOnDestroy() {
    this.buscarSubscription.unsubscribe();
  }

  buscarSobre() {
    this.buscarSubscription = this._data.buscarSobre()
      .subscribe((res: Sobre) => {
        this.sobre = res;
        this.html = this.sanitizer.bypassSecurityTrustHtml(res.descricao);
      });
  }

}
