<!-- <div class="footer-instagram">
    <img src="assets/banner-instagram.png" alt="Banner Instagram" class="footer-instagram__image">
    <div class="container">
        <div class="footer-instagram-wrapper">
            <a href="https://www.instagram.com/amarillo.estudio" target="_blank"
                class="btn btn-secondary btn-large">acessar instagram</a>
        </div>
    </div>
</div> -->

<footer class="footer">
  <div class="container">
    <div class="footer-wrapper">
      <div class="col">
        <div class="logo">
          <img src="assets/logo-dark.png" alt="Amarillo | Estúdio de Criação" />
        </div>
      </div>
      <div class="col">
        <h2 class="footer-title">Mapa do site</h2>
        <ul class="footer-menu">
          <li class="footer-menu__item">
            <a
              routerLink="/inicio"
              class="footer-menu__link"
              title="Home"
              routerLinkActive="active"
            >
              Home
            </a>
          </li>
          <li class="footer-menu__item">
            <a
              routerLink="/sobre"
              class="footer-menu__link"
              title="Sobre"
              routerLinkActive="active"
            >
              Sobre
            </a>
          </li>
          <li class="footer-menu__item">
            <a
              routerLink="/blog"
              class="footer-menu__link"
              title="Blog"
              routerLinkActive="active"
            >
              Blog
            </a>
          </li>
          <!-- <li class="footer-menu__item">
                        <a routerLink="/portfolio/v" class="footer-menu__link" title="Portfólio para você"
                            routerLinkActive="active">
                            Portfólio para você
                        </a>
                    </li> -->
          <li class="footer-menu__item">
            <a
              routerLink="/portfolio/e"
              class="footer-menu__link"
              title="Portfólio para sua empresa"
              routerLinkActive="active"
            >
              Portfólio
            </a>
          </li>
          <li class="footer-menu__item">
            <a
              routerLink="/contato"
              class="footer-menu__link"
              title="Contato"
              routerLinkActive="active"
            >
              Contato
            </a>
          </li>
        </ul>
      </div>
      <div class="col">
        <h2 class="footer-title">Entre em contato</h2>
        <ul class="footer-menu">
          <li class="footer-menu__item">
            <a
              [href]="'https://wa.me/' + contatoGeral.whatsApp"
              class="footer-menu__link"
              title="WhatsApp"
            >
              <i class="fab fa-whatsapp"></i>
              <span>{{
                contatoGeral.whatsApp | mask : "(00) 0 0000-0000"
              }}</span>
            </a>
          </li>
          <li class="footer-menu__item">
            <a
              [href]="'mailto:' + contatoGeral.email"
              class="footer-menu__link"
              title="E-mail"
            >
              <i class="fas fa-envelope"></i>
              <span>{{ contatoGeral.email }}</span>
            </a>
          </li>
          <li class="footer-menu__item">
            <a
              href="https://www.google.com/maps/dir//-29.1559551,-51.1871679/@-29.1559789,-51.1893012,17z"
              target="_blank"
              class="footer-menu__link"
              title="Localização"
            >
              <i class="fas fa-map-marker-alt"></i>
              <span>
                {{ contatoGeral.rua }}, {{ contatoGeral.numeroEndereco }},
                {{ contatoGeral.complemento }} - {{ contatoGeral.bairro }},
                {{ contatoGeral.cidade }}
              </span>
            </a>
          </li>
        </ul>
      </div>
      <div class="col">
        <h2 class="footer-title">Redes sociais</h2>
        <ul class="footer-social">
          <li class="footer-social__item" *ngIf="contatoGeral.linkFacebook">
            <a
              [href]="contatoGeral.linkFacebook"
              class="footer-social__link"
              title="Facebook"
              target="_blank"
            >
              <i class="fab fa-facebook-square"></i>
            </a>
          </li>
          <li class="footer-social__item" *ngIf="contatoGeral.linkInstagram">
            <a
              [href]="contatoGeral.linkInstagram"
              class="footer-social__link"
              title="Instagram"
              target="_blank"
            >
              <i class="fab fa-instagram"></i>
            </a>
          </li>
          <li class="footer-social__item" *ngIf="contatoGeral.linkYoutube">
            <a
              [href]="contatoGeral.linkYoutube"
              class="footer-social__link"
              title="Youtube"
              target="_blank"
            >
              <i class="fab fa-youtube"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="footer-bottom">
    <div class="container">
      <div class="footer-bottom-wrapper">
        <span
          >&copy; 2020 - Todos os direitos reservados. YourWay Digital.</span
        >
        <a
          href="https://yourway.digital"
          class="yourway"
          target="_blank"
          title="YourWay Digital"
        >
          <img
            src="https://yourway.digital/assets/logo.png"
            alt="YourWay Digital"
          />
        </a>
      </div>
    </div>
  </div>
</footer>
