import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { Portifolio } from 'src/app/models/portifolio.model';
import { Categoria } from 'src/app/models/categoria.model';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {

  portfolios: Portifolio[] = [];
  categorias: Categoria[] = [];

  idCategoria: any = '%';
  tipo: string = '';
  page: number = 1;
  noMore: boolean = false;
  loading: boolean = false;

  buscarCategoriasSubscription: Subscription;
  buscarPortfoliosSubscription: Subscription;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public _data: DataService,
    public _helper: HelperService
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(query => {
      this.portfolios = [];
      this.idCategoria = query.idCategoria ? query.idCategoria : '%';
      this.buscarPorfolios();
    }); 
    this.route.params.subscribe(param => {
      this.portfolios = [];
      this.tipo = param.slug;
      this.buscarCategorias();
      this.buscarPorfolios();
    });
  }

  ngOnDestroy() {
    this.buscarCategoriasSubscription.unsubscribe();
    this.buscarPortfoliosSubscription.unsubscribe();
  }

  buscarCategorias() {
    this.buscarCategoriasSubscription = this._data.buscarCategorias(-99, -99, this.tipo)
      .subscribe((res: Categoria[]) => this.categorias = res)
  }

  buscarPorfolios() {
    this.noMore = false;
    this.loading = true;
    this.buscarPortfoliosSubscription = this._data.buscarPortfolios(this.page, 9, this.tipo ? this.tipo : '%', this.idCategoria)
    .subscribe((res: Portifolio[]) => {
      if (res.length < 9) {
        this.noMore = true;
      }
      this.portfolios = this.page == 1 ? res : this.portfolios.concat(res);
      this.loading = true; //false
    }, e => this.loading = true); //false
    console.log(this.tipo, this.loading, this.buscarPortfoliosSubscription)
  }

  onCategoriaChange(e) {
    this.router.navigate(['/portfolio', this.tipo], {
      queryParams: {
        idCategoria: e
      }
    })
  }

  loadMore() {
    this.page++;
    this.buscarPorfolios();
  }

}
