import { Component, OnInit, Input } from '@angular/core';
import * as Rellax from 'rellax';
import { Cabecalho } from 'src/app/models/cabecalho.model';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-header-intern',
  templateUrl: './header-intern.component.html',
  styleUrls: ['./header-intern.component.scss']
})
export class HeaderInternComponent implements OnInit {

  @Input('title') title: string;
  @Input('image') imagem: string;

  cabecalho: Cabecalho = new Cabecalho();

  constructor(
    public _data: DataService
  ) {
  }

  ngOnInit(): void {
    var rellax = new Rellax('.rellax', {
      speed: -2,
      center: false,
      wrapper: null,
      round: true,
      vertical: true,
      horizontal: false
    })
    this.buscarCabecalhos();
  }

  buscarCabecalhos() {
    this._data.buscarCabecalho()
      .subscribe((res: Cabecalho) => this.cabecalho = res);
  }

  getImage() {
    let image = '';
    switch (this.title) {
      case 'Sobre':
        image = this.cabecalho.imagemSobre
        break;
      case 'Blog':
        image = this.cabecalho.imagemNoticia
        break;
      case 'Contato':
        image = this.cabecalho.imagemContato
        break;
      // case 'Para você':
      //   image = this.cabecalho.imagemPortifolioVoce
      //   break;
      case 'Para sua empresa':
        image = this.cabecalho.imagemPortifolioEmpresa
        break;
      default:
        image = 'assets/banner.png'
        break;
    }
    return this.imagem ? this.imagem : image;
  }

}
