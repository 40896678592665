import { CadastroGeral } from './../models/cadastro-geral.model';
import { ContatoGeral } from './../models/contato-geral.model';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Banner } from '../models/banner.model';
import { Noticia } from '../models/noticia.model';
import { Sobre } from '../models/sobre.model';
import { Cabecalho } from '../models/cabecalho.model';
import { ContatoMensagem } from '../models/contato-mensagem.model';
import { Portifolio } from '../models/portifolio.model';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    public api: ApiService
  ) { }

  buscarContatoGeral(): Observable<ContatoGeral> {
    return this.api.get('/site/contatoGeral/buscar');
  }

  buscarCadastroGeral(): Observable<CadastroGeral> {
    return this.api.get('/site/cadastroGeral/buscar');
  }

  buscarBanners(numeroPagina: number, registrosPorPagina: number): Observable<Banner[]> {
    return this.api.get(`/site/banner/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}`);
  }

  buscarCategorias(numeroPagina: number, registrosPorPagina: number, tipo: string): Observable<any> {
    return this.api.get(`/site/categoria/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}&tipo=${tipo}`);
  }

  buscarNoticias(numeroPagina: number, registrosPorPagina: number, tipo: string, tag: string = '%'): Observable<any> {
    return this.api.get(`/site/noticia/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}&tag=${tag}&tipo=${tipo}`);
  }

  buscarNoticia(slug: string): Observable<Noticia> {
    return this.api.get(`/site/noticia/buscar?slug=${slug}`);
  }

  buscarPortfolios(numeroPagina: number, registrosPorPagina: number, tipo: string, idCategoria: any = '%'): Observable<any> {
    return this.api.get(`/site/portifolio/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}&idCategoria=${idCategoria}&tipo=${tipo}`);
  }

  buscarPortfolio(slug: string): Observable<Portifolio> {
    return this.api.get(`/site/portifolio/buscar?slug=${slug}`);
  }

  buscarUltimasNoticias(numeroPagina: number, registrosPorPagina: number, idNoticia: number): Observable<any> {
    return this.api.get(`/site/noticia/buscarUltimas?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}&idNoticia=${idNoticia}`)
  }

  buscarSobre(): Observable<Sobre> {
    return this.api.get(`/site/sobre/buscar`);
  }

  buscarTags(): Observable<any> {
    return this.api.get(`/site/noticia/tag`);
  }

  buscarCabecalho(): Observable<Cabecalho> {
    return this.api.get(`/site/cabecalho/buscar`);
  }

  inserirMensagem(msg: ContatoMensagem) {
    return this.api.post(`/site/contatoMensagem/inserir`, msg);
  }
}
