import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { HelperService } from 'src/app/services/helper.service';
import { Noticia } from 'src/app/models/noticia.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  noticias: Noticia[] = [];
  buscarNoticiasSubscription: Subscription;

  page: number = 1;
  noMore: boolean = false;
  loading: boolean = false;

  tipo: string = 'E';
  tag: string = '%';

  tags: string[] = [];

  constructor(
    public _data: DataService,
    public _helper: HelperService
  ) { }

  ngOnInit(): void {
    this.buscarTags();
    this.buscarNoticias();
  }

  ngOnDestroy() {
    this.buscarNoticiasSubscription.unsubscribe();
  }

  buscarNoticias() {

    if (this.buscarNoticiasSubscription && !this.buscarNoticiasSubscription.closed) {
      this.buscarNoticiasSubscription.unsubscribe();
    }

    this.loading = true;

    this.buscarNoticiasSubscription = this._data.buscarNoticias(this.page, 3, this.tipo, this.tag)
      .subscribe((res: Noticia[]) => {
        if (res.length < 3) {
          this.noMore = true;
        }
        this.noticias = this.page == 1 ? res : this.noticias.concat(res);
        this.loading = false;
      }, e => this.loading = false);
  }

  buscarTags() {
    this._data.buscarTags().subscribe((res: any) => this.tags = res);
  }

  loadMore() {
    this.page++;
    this.buscarNoticias();
  }

  onTipoChange(ev) {
    this.buscarNoticias();
  }

  onTagChange(tag) {
    this.tag = tag;
    this.buscarNoticias();
  }

}
