import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Noticia } from 'src/app/models/noticia.model';
import { SafeHtml, DomSanitizer, Title, Meta } from '@angular/platform-browser';
import { DataService } from 'src/app/services/data.service';
import { ActivatedRoute } from '@angular/router';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.scss']
})
export class BlogDetailComponent implements OnInit {

  noticia: Noticia = new Noticia();
  buscarNoticiaSubscription: Subscription;

  html: SafeHtml;

  shareUrl: string = 'https://amarilloestudio.com.br';

  constructor(
    public _data: DataService,
    public _helper: HelperService,
    public sanitizer: DomSanitizer,
    public route: ActivatedRoute,
    public title: Title,
    public meta: Meta
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(param => this.buscarNoticia(param.slug));
  }

  ngOnDestroy() {
    this.buscarNoticiaSubscription.unsubscribe();
    this._helper.updateTags();
  }

  buscarNoticia(slug: string) {
    this.buscarNoticiaSubscription = this._data.buscarNoticia(slug)
      .subscribe((res: Noticia) => {
        this.noticia = res;
        this.updateTags(res);
        this.html = this.sanitizer.bypassSecurityTrustHtml(res.descricao);
      })
  }

  getTagsString(tag: string): string[] {
    const tags = tag.split(',');
    return tags;
  }

  updateTags(res: Noticia) {
    const shareUrl: string = `https://api.amarilloestudio.com.br/blog/${res.slug}`;
    this.title.setTitle(`${res.titulo} - Estúdio Amarillo`);
    this.meta.updateTag({ name: 'title', content: `${res.titulo} - Estúdio Amarillo` });
    this.meta.updateTag({ name: 'subject', content: `${res.titulo} - Estúdio Amarillo` });
    this.meta.updateTag({ name: 'description', content: `${res.descricaoSimples}` });
    this.meta.updateTag({ name: 'url', content: shareUrl });
    this.meta.updateTag({ name: 'image', content: res.imagemPequena });
    this.meta.updateTag({ property: 'og:title', content: `${res.titulo} - Estúdio Amarillo` });
    this.meta.updateTag({ property: 'og:description', content: `${res.descricaoSimples}` });
    this.meta.updateTag({ property: 'og:url', content: shareUrl });
    this.meta.updateTag({ property: 'og:image', content: res.imagemPequena });
    this.meta.updateTag({ property: 'og:image:secure_url', content: res.imagemPequena });
    this.meta.updateTag({ property: 'og:image:alt', content: `${res.titulo} - Estúdio Amarillo` });
    this.meta.updateTag({ name: 'twitter:title', content: `${res.titulo} - Estúdio Amarillo` });
    this.meta.updateTag({ name: 'twitter:description', content: `${res.descricaoSimples}` });
    this.meta.updateTag({ name: 'twitter:url', content: shareUrl });
    this.meta.updateTag({ name: 'twitter:image', content: res.imagemPequena });
    this.meta.updateTag({ name: 'twitter:image:src', content: res.imagemPequena });
  }

}
