<app-header-intern [title]="'Contato'"></app-header-intern>

<section class="contact">
    <div class="container">
        <div class="title">
            <h2 class="contact-title">
                Quer falar conosco?
            </h2>
            <p class="contact-description">
                Adoraríamos conversar com você, preencha o formulário abaixo que entraremos em contato o mais rápido
                possível!
            </p>
        </div>
        <div class="contact-wrapper">
            <div class="left">
                <form (ngSubmit)="submit(form)" #form="ngForm" class="form">
                    <div class="form-control">
                        <input type="text" placeholder="Digite seu nome*" name="nome" required autocomplete="name"
                            [(ngModel)]="_mensagem.nome" #nome="ngModel">
                        <span class="form-control__error" *ngIf="nome.invalid && (nome.touched || nome.dirty)">
                            *Preencha o nome para enviar o formulário
                        </span>
                    </div>
                    <div class="form-control">
                        <input type="email" placeholder="Digite seu e-mail*" name="email" autocomplete="email" required
                            [(ngModel)]="_mensagem.email" #email="ngModel" [pattern]="_helper.emailRegex">
                        <span class="form-control__error" *ngIf="email.invalid && (email.touched || email.dirty)">
                            *Preencha o e-mail para enviar o formulário
                        </span>
                    </div>
                    <div class="form-control">
                        <input type="tel" placeholder="Digite seu telefone*" name="telefone" required
                            [(ngModel)]="_mensagem.telefone" #telefone="ngModel" mask="(00) 0000-0000||(00) 0 0000-0000">
                        <span class="form-control__error"
                            *ngIf="telefone.invalid && (telefone.touched || telefone.dirty)">
                            *Preencha o telefone para enviar o formulário
                        </span>
                    </div>
                    <!-- <div class="radio-group">
                        <span class="radio-group-title">Selecione o tipo do book</span>
                        <div class="radio-item">
                            <input type="radio" name="tipoBook" [(ngModel)]="_mensagem.tipo"
                                (ngModelChange)="onTipoChange($event)" id="E" value="E">
                            <label for="E">Para sua empresa</label>
                        </div>
                        <div class="radio-item">
                            <input type="radio" name="tipoBook" [(ngModel)]="_mensagem.tipo"
                                (ngModelChange)="onTipoChange($event)" id="V" value="V">
                            <label for="V">Para você</label>
                        </div>
                    </div> -->
                    <div class="form-control">
                        <select name="estilo" id="estilo" required [(ngModel)]="_mensagem.estilo" #estilo="ngModel">
                            <option [value]="''">Assunto*</option>
                            <option *ngFor="let c of categorias" [value]="c.nome">{{c.nome}}</option>
                            <option [value]="'Outro'">Outro</option>
                        </select>
                        <span class="form-control__error" *ngIf="estilo.invalid && (estilo.touched || estilo.dirty)">
                            *Selecione o assunto para enviar o formulário
                        </span>
                    </div>
                    <div class="form-control">
                        <textarea name="mensagem" id="mensagem" placeholder="Sua mensagem"
                            [(ngModel)]="_mensagem.mensagem" #mensagem="ngModel" required></textarea>
                        <span class="form-control__error"
                            *ngIf="mensagem.invalid && (mensagem.touched || mensagem.dirty)">
                            *Escreva uma mensagem para enviar o formulário
                        </span>
                    </div>
                    <button type="submit" class="btn btn-default" [disabled]="loading">ENVIAR</button>
                </form>
            </div>
            <div class="right">
                <div class="contact-item">
                    <div class="contact-item-icon">
                        <i class="fas fa-envelope"></i>
                    </div>
                    <a [href]="'mailto:'+contatoGeral.email" title="E-mail">{{contatoGeral.email}}</a>
                </div>
                <div class="contact-item" *ngIf="contatoGeral.whatsApp">
                    <div class="contact-item-icon">
                        <i class="fab fa-whatsapp"></i>
                    </div>
                    <a [href]="'https://wa.me/55'+contatoGeral.whatsApp" title="Whatsapp" target="_blank">
                        {{contatoGeral.whatsApp | mask: '(00) 0 0000-0000'}}
                    </a>
                </div>
                <div class="contact-item">
                    <div class="contact-item-icon">
                        <i class="fas fa-map-marker-alt"></i>
                    </div>
                    <a href="https://www.google.com/maps/dir//-29.1559551,-51.1871679/@-29.1559789,-51.1893012,17z"
                        target="_blank" title="Endereço">
                        {{contatoGeral.rua}}, {{contatoGeral.numeroEndereco}}, {{contatoGeral.complemento}} -
                        {{contatoGeral.bairro}},
                        {{contatoGeral.cidade}}

                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<swal [title]="title" [text]="text" [icon]="icon" #swal>
</swal>