import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HelperService } from 'src/app/services/helper.service';
import { DataService } from 'src/app/services/data.service';
import { ContatoMensagem } from 'src/app/models/contato-mensagem.model';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ContatoGeral } from 'src/app/models/contato-geral.model';
import { Categoria } from 'src/app/models/categoria.model';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  @ViewChild('swal') swal: SwalComponent;

  title: string;
  text: string;
  icon: string;

  loading: boolean = false;

  _mensagem: ContatoMensagem = new ContatoMensagem();

  contatoGeral: ContatoGeral = new ContatoGeral();

  categorias: Categoria[] = [];

  constructor(
    public _data: DataService,
    public _helper: HelperService,
  ) { }

  ngOnInit(): void {
    this.buscarCategorias();
    this.buscarContatoGeral();
  }

  submit(f: NgForm) {

    if (f.invalid) {
      this._helper.formMarkAllTouched(f);
      return;
    }

    this.loading = true;

    this._data.inserirMensagem(this._mensagem)
      .subscribe((res: any) => {
        this.title = 'Enviado';
        this.text = 'Obrigado pela sua mensagem! Em breve retornaremos para você.';
        this.icon = 'success';
        f.reset();
      }, e => {
        this.title = 'Oops...';
        this.text = 'Algo de errado aconteceu. Por favor, tente novamente.';
        this.icon = 'error';
        f.reset();
      })
    setTimeout(() => {
      this.swal.fire();
      this.loading = false;
    }, 400)
  }

  buscarContatoGeral() {
    this._data.buscarContatoGeral()
      .subscribe((res: ContatoGeral) => this.contatoGeral = res);
  }

  buscarCategorias() {
    this._data.buscarCategorias(-99, -99, this._mensagem.tipo)
      .subscribe(res => this.categorias = res);
  }

  onTipoChange(ev) {
    this._mensagem.estilo = '';
    this.buscarCategorias();
  }

}
