import { Component, OnInit, AfterViewInit, ViewChild, PLATFORM_ID, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Lightbox } from 'ngx-lightbox';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/services/data.service';
import { Portifolio } from 'src/app/models/portifolio.model';
import { ActivatedRoute } from '@angular/router';
import { HelperService } from 'src/app/services/helper.service';
import { Title, Meta } from '@angular/platform-browser';
import { NgxMasonryOptions, NgxMasonryComponent } from 'ngx-masonry';

@Component({
  selector: 'app-portfolio-detail',
  templateUrl: './portfolio-detail.component.html',
  styleUrls: ['./portfolio-detail.component.scss']
})
export class PortfolioDetailComponent implements OnInit, AfterViewInit {

  @ViewChild(NgxMasonryComponent) masonry: NgxMasonryComponent;

  portfolio: Portifolio = new Portifolio();
  masonryOptions = {
    itemSelector: '.grid-item',
    columnWidth: '.grid-sizer',
    percentPosition: true,
  } as NgxMasonryOptions;

  constructor(
    public lightbox: Lightbox,
    public http: HttpClient,
    public _data: DataService,
    public _helper: HelperService,
    public route: ActivatedRoute,
    public title: Title,
    public meta: Meta
  ) {
  }

  ngOnInit(): void { }

  ngOnDestroy() {
    this._helper.updateTags();
  }

  ngAfterViewInit() {
    this.route.params.subscribe(param => this.buscarPortfolio(param.slug));
  }

  doStuff(e) {
    console.log(e);
  }

  buscarPortfolio(slug: string) {
    this._data.buscarPortfolio(slug).subscribe((res: Portifolio) => {
      this.portfolio = res;
      this.portfolio.imagens = this.portfolio.imagens.map(obj => {
        const a = {
          src: this._helper.renderImage(obj.imagem, 640),
          caption: 'Caption',
          thumb: obj.imagem,
          prepend: true,
        }
        return a;
      })
      this.updateTags(this.portfolio);
    })
  }

  open(index: number): void {
    this.lightbox.open(this.portfolio.imagens, index);
  }

  close(): void {
    this.lightbox.close();
  }

  updateTags(res: Portifolio) {
    const shareUrl: string = `https://api.amarilloestudio.com.br/blog/${res.slug}`;
    this.title.setTitle(`${res.titulo} - Estúdio Amarillo`);
    this.meta.updateTag({ name: 'title', content: `${res.titulo} - Estúdio Amarillo` });
    this.meta.updateTag({ name: 'subject', content: `${res.titulo} - Estúdio Amarillo` });
    this.meta.updateTag({ name: 'description', content: `${res.descricaoSimples}` });
    this.meta.updateTag({ name: 'url', content: shareUrl });
    this.meta.updateTag({ name: 'image', content: res.imagemPequena });
    this.meta.updateTag({ property: 'og:title', content: `${res.titulo} - Estúdio Amarillo` });
    this.meta.updateTag({ property: 'og:description', content: `${res.descricaoSimples}` });
    this.meta.updateTag({ property: 'og:url', content: shareUrl });
    this.meta.updateTag({ property: 'og:image', content: res.imagemPequena });
    this.meta.updateTag({ property: 'og:image:secure_url', content: res.imagemPequena });
    this.meta.updateTag({ property: 'og:image:alt', content: `${res.titulo} - Estúdio Amarillo` });
    this.meta.updateTag({ name: 'twitter:title', content: `${res.titulo} - Estúdio Amarillo` });
    this.meta.updateTag({ name: 'twitter:description', content: `${res.descricaoSimples}` });
    this.meta.updateTag({ name: 'twitter:url', content: shareUrl });
    this.meta.updateTag({ name: 'twitter:image', content: res.imagemPequena });
    this.meta.updateTag({ name: 'twitter:image:src', content: res.imagemPequena });
  }
}
