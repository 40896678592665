import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import { Categoria } from 'src/app/models/categoria.model';
import { DataService } from 'src/app/services/data.service';
import { Banner } from 'src/app/models/banner.model';
import { Portifolio } from 'src/app/models/portifolio.model';
import { Noticia } from 'src/app/models/noticia.model';
import { HelperService } from 'src/app/services/helper.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit {

  banners: Banner[] = [];
  categorias: Categoria[] = [];
  portfolios: Portifolio[] = [];
  noticias: Noticia[] = [];

  forkSubscription: Subscription;

  tipo: string = 'E';

  slideConfig  = {
    autoplay: true,
    autoplaySpeed: 3500,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  constructor(
    public _data: DataService,
    public _helper: HelperService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.fork();
  }

  ngOnDestroy() {
    this.forkSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    
  }

  slickInit(e) {
    console.log('slick initialized');
  }

  fork() {
    let buscarBanners = this._data.buscarBanners(1, 3);
    let buscarCategorias = this._data.buscarCategorias(1, 4, this.tipo);
    let buscarPortfolios = this._data.buscarPortfolios(1, 3, this.tipo);
    let buscarNoticias = this._data.buscarNoticias(1, 3, this.tipo);
    this.forkSubscription = forkJoin([buscarBanners, buscarCategorias, buscarPortfolios, buscarNoticias])
      .subscribe((res: any[]) => {
        this.banners = res[0];
        this.categorias = res[1];
        this.portfolios = res[2];
        this.noticias = res[3];
      })
  }

  goToPortfolio(categoria?: Categoria) {
    if (categoria) {
      this.router.navigate(['/portfolio', this.tipo.toLocaleLowerCase()], {
        queryParams: {
          idCategoria: categoria.id,
        }
      })
    } else {
      this.router.navigate(['/portfolio', this.tipo.toLocaleLowerCase()]);
    }
  }

  onTipoChange(e) {
    this.fork();
  }
  
  changeBanner(index: number) {

  }

}
