<app-header-intern [title]="tipo === 'v' ? 'Para você' : 'Para sua empresa'"></app-header-intern>

<section class="portfolio">
    <div class="container">
        <div class="portfolio-categories">
            <div class="portfolio-categories__item">
                <input type="radio" name="categorias" [(ngModel)]="idCategoria" id="todos" [value]="'%'"
                    (ngModelChange)="onCategoriaChange($event)">
                <label for="todos" [class.active]="idCategoria == '%'">Todos</label>
            </div>
            <div class="portfolio-categories__item" *ngFor="let c of categorias; let i = index;">
                <input type="radio" [(ngModel)]="idCategoria" name="categorias" [id]="'categoria'+c.id" [value]="c.id"
                    (ngModelChange)="onCategoriaChange($event)">
                <label [for]="'categoria'+c.id" [class.active]="idCategoria == c.id">{{c.nome}}</label>
            </div>
        </div>

        <div class="job-list">
            <a class="job-item" *ngFor="let p of portfolios;"
                [routerLink]="['/portfolio', p.tipo.toLowerCase(), p.slug]">
                <div class="job-item-header">
                    <img [src]="_helper.renderImageHeight(p.imagemPequena, 240)" [alt]="p.imagem">
                    <span class="job-item-tag">{{p.categoria?.nome}}</span>
                </div>
                <div class="job-item-content">
                    <h3 class="job-item-title">{{p.nome}}</h3>
                    <p>
                        {{p.descricaoSimples}}
                    </p>
                </div>
                <div class="job-item-footer">
                    <ul>
                        <li>
                            <i class="fas fa-user"></i>
                            <span>{{p.profissional}}</span>
                        </li>
                        <li>
                            <i class="fas fa-calendar-alt"></i>
                            <span>{{_helper.moment(p.data).format('DD')}} de {{_helper.moment(p.data).format('MMMM')}}
                                de {{_helper.moment(p.data).format('YYYY')}}</span>
                        </li>
                    </ul>
                </div>
            </a>
        </div>

        <div class="job-list-button" *ngIf="!noMore">
            <button class="btn btn-default" (click)="loadMore()">
                ver mais
            </button>
        </div>
    </div>
</section>