export class ContatoGeral {

    id: number = null;
    whatsApp: string = '';
    email: string = '';
    telefone: string = '';
    linkFacebook: string = '';
    linkInstagram: string = '';
    linkYoutube: string = '';
    uf: string = '';
    cidade: string = '';
    rua: string = '';
    numeroEndereco: string = '';
    bairro: string = '';
    cep: string = '';
    complemento: string = '';
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}