<ngx-slick-carousel
  class="carousel home-carousel"
  #slickModal="slick-carousel"
  [config]="slideConfig"
  (init)="slickInit($event)"
>
  <div ngxSlickItem *ngFor="let banner of banners" class="slide">
    <a [href]="banner.link">
      <img [src]="banner.imagem" alt="" width="100%" />
    </a>
  </div>
</ngx-slick-carousel>

<!-- <section class="banner"></section> -->
<!-- <div class="swiper-container" >
    <div class="swiper-wrapper">
      <img [src]="b.imagem" [alt]="b.titulo" *ngIf="!b.link">
        <div class="swiper-slide" *ngFor="let b of banners; let i = index;">
            <a [href]="b.link" *ngIf="b.link" target="_blank">
                <img [src]="b.imagem" [alt]="b.titulo">
            </a>
            <img [src]="b.imagem" [alt]="b.titulo" *ngIf="!b.link">
        </div>
    </div>

    <div class="swiper-pagination"></div>
</div> -->

<section class="home-portfolio">
  <div class="container">
    <div class="title">
      <h2 class="title-principal">Princípais categorias</h2>
      <!-- <div class="toggle">
                <div class="toggle-item">
                    <input type="radio" name="toggle-categorias" [value]="'V'" id="v1" [(ngModel)]="tipo"
                        (ngModelChange)="onTipoChange($event)">
                    <label for="v1">Para você</label>
                </div>
                <div class="toggle-item">
                    <input type="radio" name="toggle-categorias" [value]="'E'" id="e1" [(ngModel)]="tipo"
                        (ngModelChange)="onTipoChange($event)">
                    <label for="e1">Para sua empresa</label>
                </div>
            </div> -->
    </div>
    <div class="portfolio-list">
      <a
        class="portfolio-item"
        *ngFor="let c of categorias"
        (click)="goToPortfolio(c)"
      >
        <div class="portfolio-item-image">
          <img
            [src]="_helper.renderImageHeight(c.imagem, 340)"
            [alt]="c.nome"
          />
        </div>
        <h2 class="portfolio-item-label">
          {{ c.nome }}
        </h2>
      </a>
    </div>
    <div class="portfolio-list-button">
      <a
        class="btn btn-outlined btn-outlined--light btn-large"
        (click)="goToPortfolio(null)"
      >
        VER MAIS
      </a>
    </div>
  </div>
</section>

<section class="home-jobs">
  <div class="container">
    <div class="title">
      <h2>Últimos trabalhos</h2>
      <div class="right">
        <!-- <div class="toggle">
                    <div class="toggle-item">
                        <input type="radio" name="toggle-portfolios" [value]="'V'" id="v2" [(ngModel)]="tipo"
                            (ngModelChange)="onTipoChange($event)">
                        <label for="v2">Para você</label>
                    </div>
                    <div class="toggle-item">
                        <input type="radio" name="toggle-portfolios" [value]="'E'" id="e2" [(ngModel)]="tipo"
                            (ngModelChange)="onTipoChange($event)">
                        <label for="e2">Para sua empresa</label>
                    </div>
                </div> -->
        <a
          (click)="goToPortfolio(null)"
          class="btn btn-outlined btn-icon btn-outlined--light"
        >
          <span>Ver portfólio</span>
          <i class="fas fa-long-arrow-alt-right"></i>
        </a>
      </div>
    </div>
    <div class="job-list">
      <a
        class="job-item"
        *ngFor="let p of portfolios"
        [routerLink]="['/portfolio', p.tipo.toLowerCase(), p.slug]"
      >
        <div class="job-item-header">
          <img
            [src]="_helper.renderImageHeight(p.imagemPequena, 240)"
            [alt]="p.nome"
          />
          <span class="job-item-tag">{{ p.categoria?.nome }}</span>
        </div>
        <div class="job-item-content">
          <h3 class="job-item-title">{{ p.nome }}</h3>
          <p>
            {{ p.descricaoSimples }}
          </p>
        </div>
        <div class="job-item-footer">
          <ul>
            <li>
              <i class="fas fa-user"></i>
              <span>{{ p.profissional }}</span>
            </li>
            <li>
              <i class="fas fa-calendar-alt"></i>
              <span
                >{{ _helper.moment(p.data).format("DD") }} de
                {{ _helper.moment(p.data).format("MMMM") }} de
                {{ _helper.moment(p.data).format("YYYY") }}</span
              >
            </li>
          </ul>
        </div>
      </a>
    </div>
  </div>
</section>

<section class="home-blog">
  <div class="container">
    <div class="title">
      <h2>Últimas notícias</h2>
      <div class="right">
        <!-- <div class="toggle">
                    <div class="toggle-item">
                        <input type="radio" name="toggle-noticias" [value]="'V'" id="v3" [(ngModel)]="tipo"
                            (ngModelChange)="onTipoChange($event)">
                        <label for="v3">Para você</label>
                    </div>
                    <div class="toggle-item">
                        <input type="radio" name="toggle-noticias" [value]="'E'" id="e3" [(ngModel)]="tipo"
                            (ngModelChange)="onTipoChange($event)">
                        <label for="e3">Para sua empresa</label>
                    </div>
                </div> -->
        <a
          routerLink="/blog"
          class="btn btn-outlined btn-icon btn-outlined--light"
        >
          <span>Ver todas as notícias</span>
          <i class="fas fa-long-arrow-alt-right"></i>
        </a>
      </div>
    </div>
    <div class="blog-list">
      <a
        class="blog-item"
        *ngFor="let n of noticias"
        [routerLink]="['/blog', n.slug]"
      >
        <div class="blog-item-header">
          <div class="blog-item-image">
            <img
              [src]="_helper.renderImageHeight(n.imagemPequena, 240)"
              [alt]="n.titulo"
            />
          </div>
          <span class="blog-item-tag">
            {{ n.tag }}
          </span>
        </div>
        <div class="blog-item-content">
          <h3 class="blog-item-title">
            {{ n.titulo }}
          </h3>
          <p class="blog-item-description">
            {{ n.descricaoSimples | slice : 0 : 64
            }}{{ n.descricaoSimples.length >= 64 ? "..." : "" }}
          </p>
          <div class="blog-item-link">
            <span>acessar</span>
            <i class="fas fa-long-arrow-alt-right"></i>
          </div>
        </div>
      </a>
    </div>
  </div>
</section>
