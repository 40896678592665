<app-header-intern [title]="''" [image]="noticia.imagemGrande"></app-header-intern>

<section class="blog-interno">
    <ul class="social-sharing">
        <li class="social-sharing__item  social-sharing__item--facebook">
            <a [href]="'http://www.facebook.com/sharer.php?u=' + shareUrl" title="Facebook" target="_blank">
                <i class="fab fa-facebook-square facebook-color"></i>
            </a>
        </li>
        <li class="social-sharing__item social-sharing__item--twitter">
            <a [href]="'https://twitter.com/intent/tweet?url=' + shareUrl" title="Twitter" target="_blank">
                <i class="fab fa-twitter twitter-color"></i>
            </a>
        </li>
        <li class="social-sharing__item  social-sharing__item--linkedin">
            <a [href]="'http://www.linkedin.com/shareArticle?mini=true&amp;url=' + shareUrl" target="_blank">
                <i class="fab fa-linkedin-in"></i>
            </a>
        </li>
        <li class="social-sharing__item  social-sharing__item--mail">
            <a
                [href]="'mailto:contato@amarilloestudio.com.br?subject=Contato Via Site Estudio Amarillo&amp;body=Você visualizou uma notícia em nossa página. segue o endereço. ' + shareUrl">
                <i class="fas fa-envelope"></i>
            </a>
        </li>
    </ul>
    <div class="container">
        <div class="blog-interno-wrapper">
            <div class="post">
                <div class="post-header">
                    <div class="post-tags">
                        <span class="post-tags__item" *ngFor="let t of getTagsString(noticia.tag)">
                            {{t.toLocaleUpperCase()}}
                        </span>
                    </div>
                    <h2 class="post-title">
                        {{noticia.titulo}}
                    </h2>
                    <div class="post-info">
                        <div class="post-info__item">
                            <i class="fas fa-calendar-alt"></i>
                            <span>
                                {{_helper.moment(noticia.data).format('DD')}} de
                                {{_helper.moment(noticia.data).format('MMMM')}}
                                de {{_helper.moment(noticia.data).format('YYYY')}}
                            </span>
                        </div>
                        <div class="post-info__item">
                            <i class="fas fa-clock"></i>
                            <span>{{noticia.tempoLeitura}}</span>
                        </div>
                    </div>
                    <div class="post-content summernote" [innerHtml]="html"></div>
                </div>
            </div>
        </div>
    </div>
</section>