import { Component, OnInit, HostListener, Input, Inject } from '@angular/core';
import { ContatoGeral } from 'src/app/models/contato-geral.model';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input('contatoGeral') contatoGeral: ContatoGeral;

  active: boolean = false;
  menuToggle: boolean = false;

  @HostListener('window:scroll', [])
  handleScroll(): void {
    const windowScroll = window.scrollY;
    if (windowScroll >= 50) {
      this.active = true;
    } else {
      this.active = false;
    }
  }

  constructor(
    
  ) { }

  ngOnInit(): void {
  }

}
