<app-header-intern [title]="'Blog'"></app-header-intern>

<section class="blog">
    <div class="container">
        <div class="blog-wrapper">
            <div class="left">
                <div class="blog-list">
                    <a class="blog-item blog-item--full" *ngFor="let n of noticias;" [routerLink]="['/blog', n.slug]">
                        <div class="blog-item-header">
                            <div class="blog-item-image">
                                <img [src]="n.imagemPequena" [alt]="n.titulo">
                            </div>
                            <span class="blog-item-tag">
                                {{n.tag}}
                            </span>
                        </div>
                        <div class="blog-item-content">
                            <h3 class="blog-item-title">
                                {{n.titulo}}
                            </h3>
                            <p class="blog-item-description">
                                {{n.descricaoSimples}}
                            </p>
                            <div class="blog-item-link">
                                <span>acessar</span>
                                <i class="fas fa-long-arrow-alt-right"></i>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="blog-list-add">
                    <button class="btn btn-outlined block btn-outlined--light" *ngIf="!noMore && !loading"
                        (click)="loadMore()">
                        VER MAIS
                    </button>
                </div>
            </div>
            <div class="right">
                <div class="blog-filters">
                    <div class="blog-filters-item">
                        <!-- <div class="toggle">
                            <div class="toggle-item">
                                <input type="radio" name="toggle" [value]="'V'" id="v1" [(ngModel)]="tipo"
                                    (ngModelChange)="onTipoChange($event)">
                                <label for="v1">Para você</label>
                            </div>
                            <div class="toggle-item">
                                <input type="radio" name="toggle" [value]="'E'" id="e1" [(ngModel)]="tipo"
                                    (ngModelChange)="onTipoChange($event)">
                                <label for="e1">Para sua empresa</label>
                            </div>
                        </div> -->
                        <h3>Categorias</h3>
                        <ul>
                            <li>
                                <a (click)="onTagChange('%')">
                                    <span>TODOS</span>
                                    <strong></strong>
                                </a>
                            </li>
                            <li *ngFor="let tag of tags">
                                <a (click)="onTagChange(tag)">
                                    <span>{{tag}}</span>
                                    <strong></strong>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>