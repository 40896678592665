<section class="portfolio-detail-header">
    <div class="portfolio-detail-header-wrapper">
        <div class="left">
            <ul class="portfolio-detail-tags">
                <li>{{portfolio.categoria?.nome}}</li>
            </ul>
            <h1 class="portfolio-detail-title">
                {{portfolio.titulo}}
            </h1>
            <h2 class="portfolio-detail-description">
                {{portfolio.descricaoSimples}}
            </h2>
            <div class="portfolio-detail-info">
                <div class="portfolio-detail-info__item">
                    <i class="fas fa-user"></i>
                    <span>{{portfolio.profissional}}</span>
                </div>
                <div class="portfolio-detail-info__item">
                    <i class="fas fa-calendar-alt"></i>
                    <span>
                        {{_helper.moment(portfolio.data).format('DD')}} de
                        {{_helper.moment(portfolio.data).format('MMMM')}}
                        de {{_helper.moment(portfolio.data).format('YYYY')}}
                    </span>
                </div>
            </div>
        </div>
        <div class="right">
            <img [src]="_helper.renderImageHeight(portfolio.imagemGrande, 400)" [alt]="portfolio.titulo">
        </div>
    </div>
</section>

<section class="portfolio-detail">
    <div class="portfolio-list">
        <div class="grid-item" *ngFor="let img of portfolio.imagens; let i = index;" (click)="open(i)">
            <div class="grid-item-overlay">
                <i class="fas fa-search-plus"></i>
            </div>
            <img [lazyLoad]="_helper.renderImage(img.thumb, 640)" [defaultImage]="'assets/loading.gif'" />
        </div>
    </div>
</section>