<header class="header" [class.active]="active">
    <nav class="header-nav">
        <a routerLink="/" class="logo" title="Amarillo | Estúdio de Criação">
            <img src="assets/logo.png" alt="Amarillo | Estúdio de Criação">
        </a>
        <button class="header-menu-toggle hidden-desktop" (click)="menuToggle = !menuToggle">
            <i class="fas fa-bars"></i>
        </button>
        <ul class="header-menu" [class.active]="menuToggle">
            <li class="header-menu__item">
                <a routerLink="/inicio" class="header-menu__link" title="Home" routerLinkActive="active"
                    (click)="menuToggle = false">
                    Home
                </a>
            </li>
            <li class="header-menu__item">
                <a routerLink="/sobre" class="header-menu__link" title="Sobre" routerLinkActive="active"
                    (click)="menuToggle = false">
                    Sobre
                </a>
            </li>
            <li class="header-menu__item">
                <a routerLink="/blog" class="header-menu__link" title="Blog" routerLinkActive="active"
                    (click)="menuToggle = false">
                    Blog
                </a>
            </li>
            <li class="header-menu__item">
                <a routerLink="/contato" class="header-menu__link" title="Contato" routerLinkActive="active"
                    (click)="menuToggle = false">
                    Contato
                </a>
            </li>
            <!-- <li class="header-menu__item">
                <a [routerLink]="['/portfolio', 'v']" class="header-menu__link header-menu__link--destaque"
                    title="Portfólio" routerLinkActive="active" (click)="menuToggle = false">
                    Para você
                </a>
            </li> -->
            <li class="header-menu__item">
                <a [routerLink]="['/portfolio', 'e']" class="header-menu__link header-menu__link--destaque"
                    title="Portfólio" routerLinkActive="active" (click)="menuToggle = false">
                    Para sua empresa
                </a>
            </li>
            <li class="header-menu__item">
                <a [href]="contatoGeral.linkFacebook" target="_blank" class="header-menu__link social-link" title="Facebook"
                    *ngIf="contatoGeral.linkFacebook">
                    <i class="fab fa-facebook-f"></i>
                </a>
                <a [href]="contatoGeral.linkInstagram" target="_blank" class="header-menu__link social-link" title="Instagram"
                    *ngIf="contatoGeral.linkInstagram">
                    <i class="fab fa-instagram"></i>
                </a>
                <a [href]="contatoGeral.linkYoutube" *ngIf="contatoGeral.linkYoutube" class="footer-social__link social-link"
                    title="Youtube" target="_blank">
                    <i class="fab fa-youtube"></i>
                </a>
            </li>
            <li class="header-menu__item">
                <a href="https://admin.amarilloestudio.com.br" target="_blank" class="btn btn-default btn-icon">
                    <i class="fas fa-lock"></i>
                    <span>Área restrita</span>
                </a>
            </li>
        </ul>
        <div class="header-menu-backdrop hidden-desktop" [class.active]="menuToggle" (click)="menuToggle = false"></div>
    </nav>
</header>